import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import msg from "../../../../common/message";
import CommonModal from "../../../../common/CommonModal";
import axios from "axios";
import { getUserRole } from "../../../../../CommonFunction";

function ContentModal(props) {
    const { mode, data, close, sgg } = props;
    const btn_search = document.getElementById("btn_search");

    // (등록) 수정
    const [formData, setFormData] = useState();

    // 생성
    const [li, setLi] = useState([]);
    useEffect(() => {
        let liArr = [
            {
                id: "mngNo",
                label: "관리번호",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "sggCd",
                label: "구군",
                type: "select",
                option: [
                    ...sgg.map((s) => ({
                        value: s.name,
                        label: s.value,
                    })),
                ],
                required: true,
                col: "6",
            },
            {
                id: "lotNm",
                label: "주차장명",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "lotType",
                label: "구분유형",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "stAddress",
                label: "도로명주소",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "address",
                label: "지번주소",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "totalSpcs",
                label: "주차구획수",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "paper",
                label: "급지구분",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "year",
                label: "연도",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
            {
                id: "month",
                label: "월",
                type: "input",
                input_type: "text",
                col: "6",
                required: false,
            },
        ];

        liArr = liArr.map((ele) => {
            return { ...ele, readonly: true, disabled: true };
        });

        setLi(liArr);
    }, [sgg]);

    return <CommonModal list={li} mode={mode} data={data} close={close} form={setFormData} />;
}

export default ContentModal;
