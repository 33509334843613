import React, { useEffect, useState, useCallback, useRef } from "react";
import Swal from "sweetalert2";
import msg from "../../../common/message";

import { AgGridReact } from "ag-grid-react";

import axios from "axios";
import { useRecoilValue } from "recoil";
import { editRightState } from "../../../../Context.js";
import { checkUpdateAndDeleteRight, useCodeTree, isFirstAdmin } from "../../../../CommonHook";
import ContentSearch from "./ContentSearch.js";
import Modal from "../../../common/Modal";
import ContentModal from "./ContentModal.js";

export default function FileUpload() {
    const [render, setRender] = useState(true);
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});

    // 편집권 검사
    const hasEdit = useRecoilValue(editRightState);
    const hasInsert = hasEdit.find((ele) => ele.writeMode == "I");
    const hasUpdate = hasEdit.find((ele) => ele.writeMode == "U");
    const hasAdmin = hasEdit.find((ele) => ele.writeMode == "admin");

    // 지역 코드 가져오기
    const { tree: sgg, set: setSgg } = useCodeTree({ parentNm: "31000", deps: [] });

    // 그리드용 매핑 배열
    const sggMap = sgg.reduce((result, item) => {
        result[item.name] = item.value;
        return result;
    }, {});

    // column setting
    const columnDefs = [
        { field: "seq", headerName: "번호", valueGetter: "node.rowIndex + 1", sortable: true, flex: 0.3 },
        { field: "year", flex: 0.3, headerName: "연도", sortable: true },
        {
            field: "month",
            flex: 0.3,
            headerName: "월",
            sortable: true,
            valueFormatter: (params) => {
                if (params.data.lotType == "8" || params.data.lotType == "9") return "-";
                else {
                    return params.value;
                }
            },
        },
        {
            field: "sggCd",
            flex: 0.5,
            headerName: "구군",
            valueFormatter: (params) => {
                return sggMap[params.value] || params.value; // 매핑된 값이 없을 경우 기존 값 사용
            },
        },
        {
            field: "lotType",
            flex: 0.5,
            headerName: "주차유형",
            valueFormatter: (params) => {
                switch (params.value) {
                    case "1":
                        return "공영노상";
                    case "2":
                        return "공영노외";
                    case "3":
                        return "공영부설";
                    case "4":
                        return "민영노상";
                    case "5":
                        return "민영노외";
                    case "6":
                        return "민영부설";
                    case "7":
                        return "부설";
                    case "8":
                        return "부설개방";
                    case "9":
                        return "사유지개방";
                    default:
                        return "유형없음";
                }
            },
        },
        { field: "dataNm", flex: 1, headerName: "데이터명" },
        {
            field: "collectYn",
            flex: 0.5,
            headerName: "데이터승인",
            valueFormatter: (params) => {
                switch (params.value) {
                    case "Y":
                        return "완료";
                    case "N":
                        return "대기";
                    default:
                        return "반려";
                }
            },
            cellStyle: (params) => {
                const colorMap = {
                    Y: "black",
                    N: "red",
                    X: "blue",
                };
                return {
                    color: colorMap[params.value] || "black",
                    // fontWeight: params.value == "N" ? "bold" : "normal",
                };
            },
        },
        {
            field: "dupType",
            flex: 0.5,
            headerName: "중복유무",
            valueFormatter: (params) => {
                switch (parseInt(params.value)) {
                    case 1:
                        return "부분중복";
                    case 2:
                        return "완전중복";
                    default:
                        return "정상";
                }
            },
            cellStyle: (params) => {
                return {
                    color: params.value == 2 ? "red" : "green",
                    // fontWeight: params.value == "N" ? "bold" : "normal",
                };
            },
        },
        { field: "createId", flex: 0.5, headerName: "등록자" },
        { field: "createDtm", flex: 0.5, headerName: "등록일시", sortable: true },
    ];

    const defaultColDef = {
        resizable: true,
        autoHeight: true,
    };

    // search 에서 쓰는 api 를 게시판에도 재사용, 별도 호출 없음.
    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    // cell click event
    const cellClickedListener = useCallback(
        (evt) => {
            if (evt.event.target.closest("button")) return;

            // 데이터 승인 이벤트
            if (evt.colDef.field == "collectYn" && evt.value == "N" && hasInsert) {
                // 완전중복인 경우 수집X, 부분중복의 경우 관리자권한 검사
                if (evt.data.dupType == 2 && !hasAdmin) return Swal.fire(msg.alertMessage["data_absolute_dup"]);
                if (evt.data.dupType == 1 && !hasAdmin) return Swal.fire(msg.alertMessage["data_partial_dup"]);

                // 관리자
                if (hasAdmin) {
                    Swal.fire(msg.alertMessage["double_check_collect_admin"]).then((res) => {
                        const docId = evt.data.id;

                        if (res.isConfirmed) {
                            if (evt.data.dupType == 2) {
                                return Swal.fire(msg.alertMessage["data_absolute_dup"]);
                            }

                            axios("/api/data/facility/file/collect/" + docId)
                                .then((result) => {
                                    if (result.data.collectYn == "Y") document.querySelector("button").click();
                                })
                                .catch((err) => {
                                    alert(err.response.data.message);
                                });
                        } else if (res.isDenied) {
                            // [240416] 반려(데이터 승인 거부)
                            if (!isFirstAdmin()) return alert("시 관리자 권한입니다.");

                            axios("/api/data/facility/file/reject/" + docId)
                                .then((result) => {
                                    if (result.data.collectYn == "X") document.querySelector("button").click();
                                })
                                .catch((err) => {
                                    alert(err.response.data.message);
                                });
                        } else {
                            return;
                        }
                    });
                }

                // 담당자
                else {
                    Swal.fire(msg.alertMessage["double_check_collect_manager"]).then((res) => {
                        const docId = evt.data.id;

                        if (res.isConfirmed) {
                            axios("/api/data/facility/file/collect/" + docId)
                                .then((result) => {
                                    if (result.data.collectYn == "Y") document.querySelector("button").click();
                                })
                                .catch((err) => {
                                    alert(err.response.data.message);
                                });
                        } else {
                            return;
                        }
                    });
                }
            }
            // 반려일 경우 (데이터 복사 -> 신규 등록 -> 삭제 트리거)
            else if (evt.colDef.field == "collectYn" && evt.value == "X" && hasInsert) {
                setProc({ task: "proc", mode: "insert", retry: true });
                setRow({
                    afterDeleteId: evt.data.id,
                    comment: evt.data.comment,
                    lotType: evt.data.lotType,
                    sggCd: evt.data.sggCd,
                    year: evt.data.year,
                    month: evt.data.month,
                });
                setModalOpen(true);
            } else {
                if (evt.data.state == "-1") {
                    setProc({ task: "proc", mode: "insert", retry: true });
                } else {
                    setProc({ task: "proc", mode: "update" });
                }
                setRow(evt.data);
                setModalOpen(true);
            }
        },
        [hasEdit]
    );

    // modal
    const [proc, setProc] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const onOpenModal = () => {
        setRow({});
        setProc({ task: "proc", mode: "insert" });
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div>
            <div className="pageWrap">
                <ContentSearch setData={setData} render={render} sgg={sgg} />

                <div className="btnWrap flxRit">
                    <button className="btn btn_write" onClick={onOpenModal} hidden={!hasInsert}>
                        등록
                    </button>
                </div>

                <div className="ag-theme-alpine" style={{ width: "100%", height: "512px" }}>
                    <AgGridReact
                        headerHeight={40}
                        rowData={data}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                        onCellClicked={cellClickedListener}
                        pagination={true}
                        paginationPageSize={10}
                    />
                </div>
            </div>

            <Modal open={modalOpen} close={closeModal} header="주차시설 - 파일 업로드">
                <ContentModal
                    mode={proc.mode}
                    retry={proc.retry}
                    data={row}
                    close={closeModal}
                    render={() => setRender(!render)}
                    sgg={sgg}
                    collectYn={row.collectYn}
                    hasEdit={hasEdit}
                />
            </Modal>
        </div>
    );
}
