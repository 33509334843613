import React, { useEffect, useState, useCallback } from "react";

import { AgGridReact } from "ag-grid-react";

import ContentSearch from "./ContentSearch";
import Modal from "../../common/Modal";
import ContentModal from "./ContentModal";
import { useRecoilValue } from "recoil";
import { editRightState } from "../../../Context";

function Privilege(props) {
    const [data, setData] = useState();
    const [render, setRender] = useState(false);

    // 편집권 검사
    const hasEdit = useRecoilValue(editRightState);
    const hasInsert = hasEdit.find((ele) => ele.writeMode == "I");

    const [menuList, setMenuList] = useState([]);
    useEffect(() => {
        fetch("/api/menus")
            .then((res) => {
                return res.json();
            })
            .then((m) => {
                let arr = [];
                const getRouteArr = (menus, arr) => {
                    menus.forEach((menu) => {
                        arr.push(menu);

                        if (menu.children.length !== 0) {
                            getRouteArr(menu.children, arr);
                        }
                    });
                };
                getRouteArr(m, arr);
                setMenuList(arr);
            });
    }, []);

    // column setting
    const columnDefs = [
        { field: "name", headerName: "권한코드", filter: true },
        { field: "encodedNm", headerName: "권한명", filter: true },
        { field: "comment", headerName: "비고", filter: false },
    ];

    const defaultColDef = {
        resizable: true,
    };

    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    // modal
    const [proc, setProc] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const onOpenModal = () => {
        setProc({ task: "proc", mode: "insert" });
        setModalOpen(true);
        setUser({});
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    // Example cell Click event
    const [user, setUser] = useState();
    const cellClickedListener = useCallback((evt) => {
        setUser(evt.data);
        setProc({ task: "proc", mode: "update" });
        setModalOpen(true);
    }, []);

    return (
        <div>
            <div className="pageWrap">
                <ContentSearch setData={setData} render={render} />

                <div className="btnWrap flxRit">
                    <button className="btn btn_write" onClick={onOpenModal} hidden={!hasInsert}>
                        등록
                    </button>
                </div>

                <div className="tableWrap">
                    <div className="ag-theme-alpine" style={{ width: "100%", height: "540px" }}>
                        <AgGridReact
                            // rowData={tmpData}
                            rowData={data}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            onGridReady={onGridReady}
                            onCellClicked={cellClickedListener}
                            pagination={true}
                            paginationPageSize={10}
                        />
                    </div>
                </div>
            </div>

            <Modal open={modalOpen} close={closeModal} header="권한관리">
                <ContentModal
                    mode={proc.mode}
                    data={user}
                    menuList={menuList}
                    close={closeModal}
                    render={() => setRender((prevState) => !prevState)}
                />
            </Modal>
        </div>
    );
}

export default Privilege;
