import React, { useEffect, useState } from "react";
import SearchMulti from "../../../common/SearchMulti";
import { getGugunSearchOption } from "./../../../../CommonFunction";

export default function ContentSearch(props) {
    const { setData, render, sgg } = props;

    const userJSON = localStorage.getItem("user");
    let agency;

    if (userJSON) {
        const userInfo = JSON.parse(userJSON);
        agency = userInfo.agency;
    }

    const [li, setLi] = useState([]);
    const [lotTypeData, setLotTypeData] = useState("");

    useEffect(() => {
        /**
         * select의 경우 option 항상 같이 가야함
         * required : true / false => default : false
         */
        let liArr = [
            {
                id: "year",
                label: "연도",
                type: "selectYear",
                col: "4",
            },
            {
                id: "month",
                label: "월",
                type: "select",
                type: "selectMonth",
                hide: lotTypeData === "8" || lotTypeData === "9",
                col: "4",
            },
            {
                id: "sggCd",
                label: "구군",
                type: "select",
                option: [
                    { name: "전체", value: "" },
                    ...sgg.map((reg) => ({
                        name: reg.value,
                        value: reg.name,
                    })),
                ],
                ...getGugunSearchOption(),
                col: "4",
            },
            {
                // 표준관리대장 기준. 1:공영노상, 2:공영노외, 3:공영부설, 4:민영노상, 5:민영노외, 6:민영부설, 7:부설, 8:부설개방, 9:사유지개방
                id: "lotType",
                label: "주차유형",
                type: "select",
                option: [
                    // { label: "공영주차장", value: "1" },
                    { name: "전체", value: "" },
                    { name: "민영(노외)주차장", value: "5" },
                    // { name: "노외주차장", value: 3 },
                    // { name: "부설주차장", value: 4 },
                    { name: "부설개방 주차장", value: "8" },
                    { name: "사유지개방 주차장", value: "9" },
                ],
                col: "4",
                change: setLotTypeData,
            },
            {
                id: "collectYn",
                label: "데이터승인",
                type: "select",
                option: [
                    { name: "전체", value: "" },
                    { name: "완료", value: "Y" },
                    { name: "대기", value: "N" },
                    { name: "반려", value: "X" },
                ],
                col: "4",
            },
            { id: "dataNm", label: "데이터명", type: "input", col: "4" },
        ];
        setLi(liArr);
    }, [sgg, lotTypeData]);
    return <SearchMulti list={li} setData={setData} render={render} url="/api/data/facility/file/search" />;
}
