import React, { useEffect, useState } from "react";
import SearchMulti from "../../../../common/SearchMulti";
import { getGugunSearchOption } from "./../../../../../CommonFunction";

function ContentSearch(props) {
    const { setData, sgg } = props;

    const [li, setLi] = useState([]);
    useEffect(() => {
        /**
         * select의 경우 option 항상 같이 가야함
         * required : true / false => default : false
         */
        let liArr = [
            {
                id: "year",
                label: "연도",
                type: "selectYear",
                col: "4",
            },
            {
                id: "sggCd",
                label: "구군",
                type: "select",
                option: [
                    { name: "전체", value: "" },
                    ...sgg.map((reg) => ({
                        value: reg.name,
                        name: reg.value,
                    })),
                ],
                ...getGugunSearchOption(),
                col: "4",
            },
            {
                id: "lotNm",
                label: "주차장명",
                type: "input",
                col: "4",
            },
            {
                id: "address",
                label: "주소",
                type: "input",
                col: "4",
            },
            {
                id: "minSpcs",
                label: "최소주차면수",
                type: "input",
                type2: "number",
                col: "4",
            },
            {
                id: "maxSpcs",
                label: "최대주차면수",
                type: "input",
                type2: "number",
                col: "4",
            },
        ];
        setLi(liArr);
    }, [sgg]);

    return <SearchMulti list={li} setData={setData} url="/api/data/facility/read/open/prv/search" />;
}

export default ContentSearch;
