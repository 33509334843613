import React, { useEffect, useState } from "react";
import SearchMulti from "../../../../common/SearchMulti";
import { getGugunSearchOption } from "./../../../../../CommonFunction";

function ContentSearch(props) {
    const { setData, sgg, baseKeyword } = props;

    const [li, setLi] = useState([]);
    const [lotTypeData, setLotTypeData] = useState("");
    const [yearData, setYearData] = useState(baseKeyword.year);
    const [monthData, setMonthData] = useState(baseKeyword.month);

    useEffect(() => {
        /**
         * select의 경우 option 항상 같이 가야함
         * required : true / false => default : false
         */

        let liArr = [
            {
                id: "year",
                label: "연도",
                type: "selectYear",
                singleRequired: true,
                default: yearData,
                col: "4",
            },
            {
                id: "month",
                label: "월",
                type: "selectMonth",
                singleRequired: true,
                default: monthData,
                hide: lotTypeData === "8" || lotTypeData === "9",
                col: "4",
            },
            {
                id: "lotType",
                label: "주차유형",
                type: "select",
                option: [
                    { name: "전체", value: "" },
                    { name: "공영노상", value: "1" },
                    { name: "공영노외", value: "2" },
                    // { name: "공영부설", value: "3" },
                    // { name: "민영노상", value: "4" },
                    { name: "민영노외", value: "5" },
                    // { name: "민영부설", value: "6" },
                    // { name: "부설", value: "7" },
                    { name: "부설개방", value: "8" },
                    { name: "사유지개방", value: "9" },
                ],
                col: "4",
                change: setLotTypeData,
            },
            {
                id: "sggCd",
                label: "구군",
                type: "select",
                option: [
                    { name: "전체", value: "" },
                    ...sgg.map((reg) => ({
                        value: reg.name,
                        name: reg.value,
                    })),
                ],
                ...getGugunSearchOption(),
                col: "4",
            },
            {
                id: "mngNo",
                label: "일련번호",
                type: "input",
                col: "4",
            },
            {
                id: "lotNm",
                label: "주차장명",
                type: "input",
                col: "4",
            },
            {
                id: "minSpcs",
                label: "최소주차면수",
                type: "input",
                type2: "number",
                col: "4",
            },
            {
                id: "maxSpcs",
                label: "최대주차면수",
                type: "input",
                type2: "number",
                col: "4",
            },
        ];
        setLi(liArr);
    }, [sgg, lotTypeData]);

    return <SearchMulti list={li} setData={setData} url="/api/data/facility/standard/search" />;
}

export default ContentSearch;
