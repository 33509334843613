import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

import msg from "../../common/message";

import CommonModal from "../../common/CommonModal";

function ContentModal(props) {
    const { mode, data, menuList, close, render } = props;
    const [mergeMenuList, setMergeMenuList] = useState([]);

    const handleMergeMenuList = () => {
        const privilegesMap = {};
        data.privileges?.forEach((p2) => {
            privilegesMap[p2.privilegeId] = p2;
        });

        // 최적화된 mergeMenuList 생성
        const mergeMenuList = menuList.map((m) => {
            const mergePrivileges = m.privilege.map((p1) => {
                const check = privilegesMap[p1.id];
                return { ...p1, state: !!check, change: false }; // !!check는 check의 존재 여부를 boolean으로 반환
            });
            return { ...m, privilege: mergePrivileges };
        });

        return mergeMenuList;
    };

    const handleCheckboxChange = (menuId, writeYn, writeMode) => {
        setMergeMenuList((prevList) =>
            prevList.map((menu) => {
                if (menu.id === menuId) {
                    const updatedPrivilege = menu.privilege.map((priv) =>
                        priv.writeYn === writeYn && priv.writeMode === writeMode
                            ? { ...priv, state: !priv.state, change: true }
                            : priv
                    );
                    return { ...menu, privilege: updatedPrivilege };
                }
                return menu;
            })
        );
    };

    // 저장
    const insertToUpdate = (formData) => {
        if (formData !== undefined) {
            const reqData = {
                id: formData.id,
                name: formData.name,
                useYn: formData.useYn,
                encodedNm: formData.encodedNm,
                comment: formData.comment,
            };
            const privilegeList = mergeMenuList.flatMap((m) => [...m.privilege]);
            const filterPrivilegeList = privilegeList.filter((f) => f.change);

            return axios
                .patch("/api/system/role/roles", JSON.stringify({ ...reqData, privileges: filterPrivilegeList }), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((json) => {
                    Swal.fire(msg.alertMessage["insert_success"]);
                    close(false);
                    render();
                });
        }
    };

    const [formData, setFormData] = useState();
    useEffect(() => {
        if (formData !== undefined) {
            // fetch("/api/system/role/roles", {
            //     method: "post",
            //     credentials: "include",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify(data),
            // }).then((res) => {
            //     if (res.status === 200) {
            //         Swal.fire(msg.alertMessage["insert_success"]);
            //         close(false);
            //     }
            // });
        }
    }, [formData]);

    // 삭제
    const [del, setDel] = useState({ id: null, del: false });
    useEffect(() => {
        if (del.del) {
            Swal.fire(msg.alertMessage["double_check"]).then((res) => {
                if (res.isConfirmed) {
                    fetch("/api/system/role/roles/" + del.id.id, {
                        method: "DELETE",
                    }).then((res) => {
                        if (res.status === 200) {
                            Swal.fire(msg.alertMessage["delete"]);
                            close(true);
                            // ag-grid 갱신
                            document.querySelector(".btn_search").click();
                        } else {
                            Swal.fire(msg.alertMessage["delete_fail"]);
                            return;
                        }
                    });
                } else {
                    // res.dismiss === Swal.DismissReason.cancel;
                    setDel({ id: null, del: false });
                    return;
                }
            });
        }
    }, [del]);

    // 생성
    const [li, setLi] = useState([]);
    const [dtl, setDtl] = useState(null);
    useEffect(() => {
        let liArr = [
            {
                id: "name",
                label: "코드",
                type: "input",
                input_type: "text",
                col: "6",
                required: true,
                msg: "필수 입력 값 입니다.",
                readonly: mode === "update" ? true : false,
            },
            {
                id: "encodedNm",
                label: "코드명",
                type: "input",
                input_type: "text",
                col: "6",
                required: true,
                msg: "필수 입력 값 입니다.",
            },
            {
                id: "useYn",
                label: "사용유무",
                type: "select",
                option: [
                    { label: "사용", value: "Y" },
                    { label: "미사용", value: "N" },
                ],
                col: "6",
            },
            {
                id: "comment",
                label: "비고",
                type: "textarea",
                col: "12",
            },
        ];

        setLi(liArr);

        let tmpObj = {};

        let merge = handleMergeMenuList();
        setMergeMenuList(merge);
        tmpObj["data"] = merge;

        tmpObj["columnDefs"] = [
            {
                field: "name",
                headerName: "메뉴명",
                filter: false,
                cellRenderer: (params) => {
                    return <div style={{ textAlign: "left" }}>{params.data.name}</div>;
                },
            },
            {
                field: "url",
                headerName: "url",
                filter: false,
                flex: 1,
                cellRenderer: (params) => {
                    return <div style={{ textAlign: "left" }}>{params.data.url}</div>;
                },
            },
            {
                field: "READ_PRIVILEGE",
                headerName: "읽기",
                filter: false,
                width: 80,
                cellRenderer: (params) => {
                    return (
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={
                                    params.data.privilege.find((p) => p.writeYn == "N" && p.state) ? true : false
                                }
                                onChange={() => handleCheckboxChange(params.data.id, "N", null)}
                            />
                        </div>
                    );
                },
            },
            {
                field: "INSERT_PRIVILEGE",
                headerName: "등록",
                width: 80,
                filter: false,
                cellRenderer: (params) => {
                    return (
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={
                                    params.data.privilege.find(
                                        (p) => p.writeYn == "Y" && p.writeMode === "I" && p.state
                                    )
                                        ? true
                                        : false
                                }
                                onChange={() => handleCheckboxChange(params.data.id, "Y", "I")}
                            />
                        </div>
                    );
                },
            },
            {
                field: "UPDATE_PRIVILEGE",
                headerName: "수정",
                width: 80,
                filter: false,
                cellRenderer: (params) => {
                    return (
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={
                                    params.data.privilege.find(
                                        (p) => p.writeYn == "Y" && p.writeMode === "U" && p.state
                                    )
                                        ? true
                                        : false
                                }
                                onChange={() => handleCheckboxChange(params.data.id, "Y", "U")}
                            />
                        </div>
                    );
                },
            },
            {
                field: "DELETE_PRIVILEGE",
                headerName: "삭제",
                width: 80,
                filter: false,
                cellRenderer: (params) => {
                    return (
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={
                                    params.data.privilege.find(
                                        (p) => p.writeYn == "Y" && p.writeMode === "D" && p.state
                                    )
                                        ? true
                                        : false
                                }
                                onChange={() => handleCheckboxChange(params.data.id, "Y", "D")}
                            />
                        </div>
                    );
                },
            },
        ];

        setDtl(tmpObj);
    }, []);

    return (
        <CommonModal
            list={li}
            dtl={dtl}
            mode={mode}
            data={data}
            close={close}
            form={setFormData}
            insertToUpdate={insertToUpdate}
            // setDel={setDel}
            updateAuth={true}
        />
    );
}

export default ContentModal;
