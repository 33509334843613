import { useEffect, useState, useMemo, useRef } from "react";
import { useRecoilState, atom } from "recoil";
import axios from "axios";
import { FunnelChart } from "recharts";

const cachedCodeState = atom({
    key: "cachedCodeState",
    default: {},
});

// 코드 트리 hook
export function useCodeTree({ parentNm, deps }) {
    const [tree, set] = useState([]);
    const [cachedResult, setCachedResult] = useRecoilState(cachedCodeState);

    useEffect(() => {
        if (cachedResult[parentNm]) {
            set(cachedResult[parentNm]);
        } else {
            axios
                .get(`/api/system/code/codes/search/${parentNm}`)
                .then((res) => {
                    const json = res.data;

                    const sortedChildren = json.children && json.children.sort((a, b) => a.name - b.name);
                    set(sortedChildren);
                    setCachedResult({ ...cachedResult, [parentNm]: sortedChildren });
                })
                .catch((err) => {});
        }
    }, [parentNm, cachedResult, setCachedResult]);

    return { tree, set };
}

// 구군 검색 코드 가져오기
export function useSearchGugunCodeTree({ deps }) {
    const [tree, set] = useState([]);
    const [cachedResult, setCachedResult] = useRecoilState(cachedCodeState);

    const parentNm = "31000";

    useEffect(() => {
        if (cachedResult[parentNm]) {
            set(cachedResult[parentNm]);
        } else {
            axios
                .get(`/api/system/code/codes/search/${parentNm}`)
                .then((res) => {
                    // 권한별 필터링
                    const userJSON = localStorage.getItem("user");

                    if (userJSON) {
                        const userInfo = JSON.parse(userJSON);
                        const agency = userInfo.agency;

                        const json = res.data;
                        let childrens = json.children && json.children.sort((a, b) => a.name - b.name);

                        if (agency != "31000") {
                            childrens = childrens.filter((ele) => ele.name == agency);
                        } else {
                            childrens.unshift({ name: "전체", value: "" });
                        }

                        set(childrens);
                        setCachedResult({ ...cachedResult, [parentNm]: childrens });
                    } else {
                        return;
                    }
                })
                .catch((err) => {});
        }
    }, [cachedResult, setCachedResult]);

    return { tree, set };
}

/**
 * 수정, 삭제 권한 판단. true: 권한 있음, false: 없음.
 * @param {boolean} hasEdit 편집권 유무
 */
export function checkUpdateAndDeleteRight(hasEdit) {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    return hasEdit && userInfo.roleEncodedNm.includes("관리자");
}

/**
 * 시 관리자(최고 관리자) 여부 판단
 * @returns true: 시 관리자, false: 그 외
 */
export function isFirstAdmin() {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    return userInfo.roleEncodedNm.includes("시 관리자");
}
